.play-button {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-button:hover {
  background-color: #f0f0f0;
}

.play-icon {
  width: 0;
  height: 0;
  border-left: 20px solid #000;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  margin-left: 5px; /* Adjust to center the triangle */
}
