/* Reset margins and padding */
html, body {
  margin: 0;
  padding: 0;
  height: 100%; /* Ensure body and html take full height */
}

/* Set the entire background to black and center the elements */
.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: #000; /* Black background */
  margin: 0;
}

/* Logo styling */
.app-logo {
  width: 300px;
  height: auto;
  margin-bottom: 20px; /* Add some space below the logo */
}

/* Content box styling */
.content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.text-input-container {
  margin-bottom: 20px; /* Adjust spacing between input and play button */
}

/* Play button styling */
.play-button {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-button:hover {
  background-color: #f0f0f0;
}

/* Play icon styling */
.play-icon {
  width: 0;
  height: 0;
  border-left: 20px solid #000;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  margin-left: 5px;
}

/* Text input styling */
.text-input {
  width: 400px;
  padding: 17px;
  border: 3px solid #fff;
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  resize: vertical;
  box-sizing: border-box;
  outline: none;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
}

.text-input::placeholder {
  color: #aaa;
}

.text-input:focus {
  border-color: #fff;
  box-shadow: inset 0 0 0 3px #000;
}

/* Controls container styling */
.controls-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px; /* Adjust spacing as needed */
}

/* Speaker select styling */
.speaker-select {
  margin-right: 20px; /* Space between select box and Play button */
  padding: 10px;
  border: 3px solid #fff;
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-family: 'Consolas', 'Menlo', 'Courier New', Courier, monospace;
  font-weight: 200; /* Make the font slightly bolder */
  outline: none;
}

/* Remove any special focus styling */
.speaker-select:focus {
  /* No special focus styling */
}

/* Option styling */
.speaker-select option {
  background-color: #fff;
  color: #000;
  font-family: 'Consolas', 'Menlo', 'Courier New', Courier, monospace;
  font-weight: 200;
}
