.text-input {
  width: 400px;
  padding: 17px;
  border: 3px solid #fff;
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  resize: vertical;
  box-sizing: border-box;
  outline: none;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;

  /* Monospaced font with thicker appearance */
  font-family: 'Consolas', 'Menlo', 'Courier New', Courier, monospace;
  font-weight: 200; /* Make the font slightly bolder */
}

.text-input::placeholder {
  color: #aaa;
  font-family: 'Consolas', 'Menlo', 'Courier New', Courier, monospace; /* Same font for the placeholder */
  font-weight: 200; /* Apply the same thickness */
}

.text-input:focus {
  border-color: #fff;
  outline: none;
  box-shadow: none;
}
